
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "beo_db_group",
  emits: ["newDataSet"],
  props: ["dbGroupWildcards"],
  data() {
    return {
      beofficeDbGroupModal: "beofficeDbGroupModal",
      formRef: "addServer",
      loading: ref<boolean>(false),
      wildcardListData: ref<string[]>([]),
      groupName: ref<string | null>(null),
      selectedServer: ref<number | null>(null),
      selectedWildcard: ref<string[]>([]),
      selectedGroup: ref<number | null>(null),
      wildcardRadio: ref<string>(""),
      newGroupName: ref<string>(""),
    };
  },
  methods: {
    async editGroup() {
      try {
        if (!this.selectedGroup) throw new Error("There is no data");

        const groupId = this.dbGroupWildcards[this.selectedGroup].id;
        await ApiService.vueInstance.axios.put(`database-group/v1/${groupId}`, {
          name: this.newGroupName,
        });
        this.selectedGroup = null;
        this.wildcardRadio = "";
        this.newGroupName = "";
        this.loading = false;
        this.$emit("newDataSet");
        hideModal(this.$refs[this.beofficeDbGroupModal] as HTMLElement);
      } catch (error) {
        this.errorHandler(error);
      }
    },
    async deleteGroup() {
      try {
        if (!this.selectedGroup) throw new Error("There is no data");

        const groupId = this.dbGroupWildcards[this.selectedGroup].id;
        await ApiService.vueInstance.axios.delete(
          `database-group/v1/${groupId}`
        );
        this.selectedGroup = null;
        this.wildcardRadio = "";
        this.loading = false;
        this.$emit("newDataSet");
        hideModal(this.$refs[this.beofficeDbGroupModal] as HTMLElement);
      } catch (error) {
        this.errorHandler(error);
      }
    },
    submit() {
      this.loading = true;
      if (this.wildcardRadio === "Edit") this.editGroup();
      else this.deleteGroup();
    },
    resetInput() {
      this.selectedWildcard.splice(0);
    },
    async getWildCardList(id: number) {
      try {
        const serverWildcard = await ApiService.vueInstance.axios.get(
          `database/v1/server/${id}/wildcard/list`
        );
        this.wildcardListData.splice(
          0,
          this.wildcardListData.length,
          ...serverWildcard.data.data
        );
      } catch (error) {
        this.errorHandler(error);
      }
    },
    errorHandler(errorData) {
      if (errorData instanceof Error) {
        // ✅ TypeScript knows err is Error
        ElMessage.error(errorData.message);
      } else {
        ElMessage.error("Unexpected error");
      }
    },
  },
  watch: {
    selectedServer(value) {
      this.getWildCardList(value);
    },
  },
});
