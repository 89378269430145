
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { ElForm, ElMessage } from "element-plus";

interface IInputData {
  employee_division_id: number | null;
  request_type_id: number | null;
}

export default defineComponent({
  name: "add_setting_data",
  emits: ["newDataSet"],
  props: ["divisions", "access"],
  data() {
    return {
      addSettingDataRef: "addSettingData",
      formData: ref<IInputData>({
        employee_division_id: null,
        request_type_id: null,
      }),
      formRef: "addServer",
      loading: ref<boolean>(false),
      rules: {
        value: [
          {
            required: true,
            message: "Data Value is required",
            trigger: "change",
          },
        ],
      },
      selectedDivision: ref<number | null>(null),
      selectedAccess: ref<number | null>(null),
    };
  },
  methods: {
    mapDivision: function (inputData: IInputData) {
      ApiService.vueInstance.axios
        .post(`employee-division/request-type/v1/`, inputData)
        .then(() => {
          // reset state
          this.loading = false;
          this.formData.employee_division_id = null;
          this.formData.request_type_id = null;
          this.selectedDivision = null;
          this.selectedAccess = null;
          hideModal(this.$refs[this.addSettingDataRef] as HTMLElement);
          ElMessage({
            type: "success",
            message: `Success map data.`,
          });
          this.$emit("newDataSet");
        })
        .catch((err) => {
          Swal.fire({
            title: err.response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        });
      this.loading = false;
    },
    submit() {
      this.loading = true;

      if (!this.selectedDivision && !this.selectedAccess) {
        return;
      }

      const input = {
        employee_division_id: this.selectedDivision,
        request_type_id: this.selectedAccess,
      };

      (this.$refs[this.formRef] as typeof ElForm).validate((valid) => {
        if (valid) {
          this.loading = true;
          this.mapDivision(input);
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    },
  },
});
