
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { ElForm, ElMessage } from "element-plus";

interface IData {
  value: string;
}

interface IInputData {
  field: string;
  value: string;
}

export default defineComponent({
  name: "add_setting_data",
  emits: ["newDataSet"],
  data() {
    return {
      addSettingDataRef: "addSettingData",
      formData: ref<IData>({
        value: "",
      }),
      formRef: "addServer",
      loading: ref<boolean>(false),
      rules: {
        value: [
          {
            required: true,
            message: "Data Value is required",
            trigger: "change",
          },
        ],
      },
      selectedField: ref<string | null>(null),
    };
  },
  methods: {
    fieldOption() {
      return this.pos?.map((el) => {
        return {
          label: el.name,
          value: el.id,
        };
      });
    },
    addNewServer: function (inputData: IInputData) {
      ApiService.vueInstance.axios
        .post(`${inputData?.field}/v1/`, {
          name: inputData.value,
        })
        .then(() => {
          // reset state
          this.loading = false;
          this.formData.value = "";
          this.selectedField = null;

          hideModal(this.$refs[this.addSettingDataRef] as HTMLElement);
          ElMessage({
            type: "success",
            message: `Success add new Item: ${
              inputData.value
            } to ${inputData.field.split("-").join(" ")}`,
          });
          this.$emit("newDataSet");
        })
        .catch((err) => {
          Swal.fire({
            title: err.response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        });
      this.loading = false;
    },
    submit() {
      this.loading = true;
      const fieldIndex = Number(this.selectedField || 0);
      const input = {
        field: this.pos[fieldIndex - 1]?.key,
        value: this.formData.value,
      };

      if (!this.$refs[this.formRef]) {
        return;
      }

      (this.$refs[this.formRef] as typeof ElForm).validate((valid) => {
        if (valid) {
          this.loading = true;
          this.addNewServer(input);
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    },
  },
  setup() {
    const pos = [
      {
        id: 1,
        name: "Division",
        key: "employee-division",
      },
      {
        id: 2,
        name: "Request Type",
        key: "request-type",
      },
      {
        id: 3,
        name: "Team Lead",
        key: "employee-position",
      },
    ];
    return {
      pos,
    };
  },
});
