
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import ApiService from "@/core/services/ApiService";
import { ElMessage } from "element-plus";

// interface IMapDb {
//   name: string | null;
//   db_server_id: number | null;
//   wildcards: string[];
// }

export default defineComponent({
  name: "add_setting_data",
  emits: ["newDataSet"],
  props: ["divisions", "access", "servers", "groupList"],
  data() {
    return {
      addSettingDataRef: "addSettingData",
      formRef: "addServer",
      loading: ref<boolean>(false),
      wildcardListData: ref<string[]>([]),
      groupName: ref<string | null>(null),
      selectedServer: ref<number | null>(null),
      selectedWildcard: ref<string[]>([]),
      addDirection: ref<string>("New Group"),

      selectedGroup: ref<number | null>(null),
    };
  },
  methods: {
    async addNewGroup() {
      if (!this.groupName && !this.selectedServer) {
        return;
      }

      this.loading = true;
      try {
        const mapDb = await ApiService.vueInstance.axios.post(
          "database-group/v1/",
          {
            name: this.groupName,
            db_server_id: this.selectedServer,
            wildcards: this.selectedWildcard,
          }
        );
        this.loading = false;
        if (mapDb.status !== 200) {
          ElMessage({
            type: "warning",
            message: `Something happened. please try again`,
          });
        }
        hideModal(this.$refs[this.addSettingDataRef] as HTMLElement);
        ElMessage({
          type: "success",
          message: `Success map data.`,
        });
        this.$emit("newDataSet");
      } catch (error) {
        this.errorHandler(error);
      }
    },
    async addToGroup() {
      if (!this.selectedGroup && this.selectedWildcard.length == 0) {
        return;
      }
      try {
        const dbGroupId =
          this.selectedGroup && this.groupList[this.selectedGroup].id;
        await ApiService.vueInstance.axios.post("database-group/wildcard/v1/", {
          db_group_id: dbGroupId,
          wildcards: this.selectedWildcard,
        });
        this.loading = false;
        hideModal(this.$refs[this.addSettingDataRef] as HTMLElement);
        ElMessage({
          type: "success",
          message: `Success map data.`,
        });
        this.$emit("newDataSet");
      } catch (error) {
        this.errorHandler(error);
      }
    },
    submit() {
      this.loading = true;
      this.addDirection == "New Group" ? this.addNewGroup() : this.addToGroup();
    },
    resetInput() {
      this.selectedWildcard.splice(0);
    },
    async getWildCardList(id: number) {
      try {
        const serverWildcard = await ApiService.vueInstance.axios.get(
          `database/v1/server/${id}/wildcard/list`
        );
        this.wildcardListData.splice(
          0,
          this.wildcardListData.length,
          ...serverWildcard.data.data
        );
      } catch (error) {
        this.errorHandler(error);
      }
    },
    errorHandler(errorData) {
      if (errorData instanceof Error) {
        // ✅ TypeScript knows err is Error
        ElMessage.error(errorData.message);
      } else {
        ElMessage.error("Unexpected error");
      }
    },
  },
  watch: {
    selectedServer(value) {
      this.getWildCardList(value);
    },
    selectedGroup(value) {
      this.getWildCardList(this.groupList[value].database_server_id);
    },
  },
});
