
import { defineComponent, ref } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import ApiService from "@/core/services/ApiService";

// Component Import
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import AddSettingDataModal from "@/views/crafted/modals/general/addSettingData.vue";
import MapDatabaseSquad from "@/views/crafted/modals/general/MapDbSquad.vue";
import MapDivisionAccess from "@/views/crafted/modals/general/MapDivisionAccess.vue";
import DatabaseGroupModal from "@/views/crafted/modals/general/DatabaseGroupModal.vue";

interface IDropdownData {
  id: string;
  name: string;
}

interface ITree {
  id: number;
  label: string;
  children?: ITree[];
}

interface IDbGroupWildcards {
  id: number;
  database_server_id: number;
  name: string;
}

interface IWildcardGroup {
  id: number;
  database_group_id: number;
  wildcard: string;
}

export default defineComponent({
  name: "feature-data-setting",
  components: {
    Datatable,
    AddSettingDataModal,
    MapDivisionAccess,
    MapDatabaseSquad,
    DatabaseGroupModal,
  },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      divisions: ref<IDropdownData[]>([]),
      types: ref<IDropdownData[]>([]),
      positions: ref<IDropdownData[]>([]),
      servers: ref<IDropdownData[]>([]),
      tableHeader: [
        {
          key: "no",
        },
        {
          name: "Item",
          key: "item",
          sortable: true,
        },
        {
          name: "Action",
          key: "action",
        },
      ],
      mapSearchText: ref<string>(""),
      defaultProps: ref({
        children: "children",
        label: "label",
      }),
      mappingData: ref<Array<ITree>>([]),
      dbGroupWildcards: ref<IDbGroupWildcards[]>([]),
      wildcardGroup: ref<IWildcardGroup[]>([]),
    };
  },
  methods: {
    async editItem(item, field) {
      try {
        const { value } = await ElMessageBox.prompt("Input new value", "Edit", {
          confirmButtonText: "Update",
          cancelButtonText: "Cancel",
        });
        if (value !== null) {
          await ApiService.vueInstance.axios.put(`${field}/v1/${item.id}`, {
            new_name: value,
          });
          ElMessage({
            type: "success",
            message: `update data to: ${value}`,
          });
          this.getList();
        }
      } catch (error) {
        ElMessage({
          type: "info",
          message: "Update canceled",
        });
      }
    },
    async deleteItem(item, field) {
      try {
        await ElMessageBox.confirm(
          "Data will deleted permanently. Continue?",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        );
        await ApiService.vueInstance.axios.delete(`${field}/v1/${item.id}`);
        ElMessage({
          type: "success",
          message: "Delete completed",
        });
        this.getList();
      } catch (error) {
        ElMessage({
          type: "info",
          message: "Delete canceled",
        });
      }
    },
    async editWildcard(id: number) {
      try {
        const { value } = await ElMessageBox.prompt(
          "Input new value",
          "New Group Name",
          {
            confirmButtonText: "Update",
            cancelButtonText: "Cancel",
          }
        );
        if (value !== null) {
          await ApiService.vueInstance.axios.put(`database-group/v1/${id}`, {
            name: value,
          });
          ElMessage({
            type: "success",
            message: `update data to: ${value}`,
          });
          this.getList();
        }
      } catch (error) {
        ElMessage({
          type: "info",
          message: "Update canceled",
        });
      }
    },
    async deleteWildcard(flag: string, id: number, groupId: number) {
      try {
        await ElMessageBox.confirm(
          "Data will deleted permanently. Continue?",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        );
        const endpoint = `database-group${flag}/v1/${id}`;
        await ApiService.vueInstance.axios.delete(endpoint);
        await this.getDbGroupWildcard(groupId);
        ElMessage({
          type: "success",
          message: "Delete completed",
        });
      } catch (error) {
        ElMessage({
          type: "info",
          message: "Delete canceled",
        });
      }
    },
    filterNode(value: string, data: ITree) {
      if (!value) return true;
      return data.label.includes(value);
    },
    errorHandler(errorData) {
      if (errorData instanceof Error) {
        // ✅ TypeScript knows err is Error
        ElMessage.error(errorData.message);
      } else {
        ElMessage.error("Unexpected error");
      }
    },
    async getTypeList() {
      try {
        const fetchedList = await ApiService.vueInstance.axios(
          "request-type/v1/list"
        );
        const sorted = fetchedList.data.data.sort(
          (a, b) => Number(a.id) - Number(b.id)
        );
        this.types.splice(0, this.types.length, ...sorted);
      } catch (error) {
        this.errorHandler(error);
      }
    },
    async getDivisionList() {
      try {
        const employeeDivisionList = await ApiService.vueInstance.axios(
          "employee-division/v1/list"
        );
        this.divisions.splice(
          0,
          this.divisions.length,
          ...employeeDivisionList.data.data
        );
      } catch (error) {
        this.errorHandler(error);
      }
    },
    async getEmployeePosition() {
      try {
        const positionList = await ApiService.vueInstance.axios(
          "employee-position/v1/list"
        );
        this.positions.splice(
          0,
          this.positions.length,
          ...positionList.data.data
        );
      } catch (error) {
        this.errorHandler(error);
      }
    },
    async getEmployeeDivisionRequestType() {
      try {
        const mapData = await ApiService.vueInstance.axios(
          "employee-division/request-type/v1/list"
        );

        const newMapObj = {};
        mapData.data.data.forEach((el) => {
          if (newMapObj[el.employee_division.name]) {
            newMapObj[el.employee_division.name] = {
              id: el.employee_division.id,
              label: el.employee_division.name,
              children: [
                ...newMapObj[el.employee_division.name].children,
                { id: el.id, label: el.request_type.name },
              ],
            };
          } else {
            newMapObj[el.employee_division.name] = {
              id: el.employee_division.id,
              label: el.employee_division.name,
              children: [{ id: el.id, label: el.request_type.name }],
            };
          }
        });
        const treeObj: ITree[] = Object.values(newMapObj);

        this.mappingData.splice(0, this.mappingData.length, ...treeObj);
      } catch (error) {
        this.errorHandler(error);
      }
    },
    async getServerlist() {
      try {
        const serverList = await ApiService.vueInstance.axios.get(
          "database/v1/server/list"
        );
        const serverData = serverList.data.data.map((el) => {
          return {
            id: el.server_id,
            name: el.name,
          };
        });
        this.servers.splice(0, this.servers.length, ...serverData);
      } catch (error) {
        this.errorHandler(error);
      }
    },
    async getDbGroup() {
      try {
        const response = await ApiService.vueInstance.axios.get(
          `database-group/v1/list`
        );
        if (response.data.data) {
          this.dbGroupWildcards.splice(
            0,
            this.dbGroupWildcards.length,
            ...response.data.data
          );
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },
    async getDbGroupWildcard(dbGroupId: number) {
      try {
        const response = await ApiService.vueInstance.axios.get(
          `database-group/wildcard/v1/list?db_group_id=${dbGroupId}`
        );
        if (response.data.data) {
          this.wildcardGroup.splice(
            0,
            this.wildcardGroup.length,
            ...response.data.data
          );
        }
      } catch (error) {
        this.errorHandler(error);
      }
    },
    getList() {
      Promise.all([
        this.getDivisionList(),
        this.getTypeList(),
        this.getServerlist(),
        this.getEmployeePosition(),
        this.getEmployeeDivisionRequestType(),
        this.getDbGroup(),
      ]);
    },
  },
  mounted() {
    this.getList();
  },
});
